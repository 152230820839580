<template>
    <div class="register login">
        <div class="login-box">
            <div class="logo-box">
                <img src="../../assets/logo.png" alt="">
            </div>
            <h1 class="login-title">{{ $t('login.title') }}</h1>

            <div class="form-box">
                <p class="form-label">{{ $t('账号') }}</p>
                <div class="form-input__box" style="margin-bottom: 18px;">
                    <input type="text" :placeholder="$t('register.input_placeholder1')" v-model="username">
                </div>
                <p class="form-label">{{ $t('密码') }}</p>
                <div class="form-input__box" style="margin-bottom: 18px;">
                    <input :type="inputType" :placeholder="$t('register.input_placeholder2')" v-model="password">
                    <!-- <van-icon name="eye-o" size="24" v-if="inputType === 'text'" @click="inputType = 'password'"/>
				<van-icon name="closed-eye" size="24" v-else @click="inputType = 'text'"/> -->
                    <img src="../../assets/icon-eye-open.png" class="icon-eye" v-if="inputType === 'text'" @click="inputType = 'password'" alt="">
                    <img src="../../assets/icon-eye-close.png" class="icon-eye" v-else @click="inputType = 'text'" alt="">
                </div>
                <p class="form-label">{{ $t('确认密码') }}</p>
                <div class="form-input__box" style="margin-bottom: 18px;">
                    <input :type="inputType1" :placeholder="$t('register.input_placeholder3')" v-model="confirm_password">
                    <!-- <van-icon name="eye-o" size="24" v-if="inputType1 === 'text'" @click="inputType1 = 'password'"/>
				<van-icon name="closed-eye" size="24" v-else @click="inputType1 = 'text'"/> -->
                    <img src="../../assets/icon-eye-open.png" class="icon-eye" v-if="inputType1 === 'text'" @click="inputType1 = 'password'" alt="">
                    <img src="../../assets/icon-eye-close.png" class="icon-eye" v-else @click="inputType1 = 'text'" alt="">
                </div>
                <p class="form-label">{{ $t('邀请码') }}</p>
                <div class="form-input__box" style="margin-bottom: 18px;">
                    <input type="text" :placeholder="$t('register.input_placeholder4')" v-model="invitation_code">
                </div>
            </div>

            <div style="height: 39px;"></div>
            <button :disabled="loading" class="login-btn" @click="onSubmit">{{ $t('register.register') }}</button>
            <router-link to="/login" class="login-register__text">{{ $t('register.text1') }}，{{ $t('register.to_login') }}</router-link>
        </div>
    </div>
</template>

<script>
import { register } from '@/api';
import { mapMutations, mapState } from "vuex";

export default {
    data() {
        return {
            inputType: 'password',
            inputType1: 'password',
            username: '',
            password: '',
            confirm_password: '',
            loading: false,
            invitation_code: ''
        };
    },
    computed: {
        ...mapState(['config'])
    },
    methods: {
        ...mapMutations(['setToken']),
        nameChange() {
            this.username = this.username.replace(/^[\u4e00-\u9fa5]{0,}$/g, '');
        },
        onSubmit() {
            if (!this.username) {
                this.$toast(this.$t('register.input_placeholder1'));
                return;
            }
            if (!this.password) {
                this.$toast(this.$t('register.input_placeholder2'));
                return;
            }
            if (this.password !== this.confirm_password) {
                this.$toast(this.$t('register.toast1'));
                return;
            }
            if (this.config.need_invitation_code?.value === 'true' && !this.invitation_code) {
                this.$toast(this.$t('register.input_placeholder4'));
                return;
            }
            this.loading = true
            register({
                username: this.username,
                password: this.password,
                invitation_code: this.invitation_code
            }).then(({ code, data, message }) => {
                this.loading = false
                this.$toast(message);
                if (code === 200) {
                    this.setToken(data.token);
                    setTimeout(() => {
                        this.$router.back();
                    }, 1500)
                }
            });
        }
    }
}
</script>

<style lang="less">
@import "../login.less";
.register-a {
    color: #1989fa;
}
.register-title {
    text-align: center;
    font-weight: 400;
    padding-bottom: 26px;
}
.register {
    padding: 26px;
}
</style>
